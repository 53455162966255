// import Board from './board';

export default class IPE {
    // Iterative policy evaluation.
    constructor(p, d, title, xPos, yPos, gw) {
        const kat = require('katex');
        this.p = p;
        this.d = d;
        this.title = title;
        this.xPos = xPos;
        this.yPos = yPos;
        this.xMargin = this.xPos + this.d.halfMargin;
        this.yMargin = this.yPos + this.d.margin;
        this.gw = gw;
        this.theta = 0.001;
        this.alpha = 0.1;
        this.gamma = 0.9;
        this.stateValues = new Array(this.d.numStates).fill(0);
        this.Delta = 1;
        this.currentV = 0;
        this.currentStateindex = -1;
        this.currentS = -1;
        this.converged = false;

        this.algoDict = {
            // 0: { depth: 0, text: '\\textbf{Iterative policy evaluation}', fn: undefined },
            0: {
                depth: 0,
                text: '\\text{Loop until } \\Delta < \\theta:',
                fn: this.checkConvergence.bind(this)
            },
            1: { depth: 1, text: '\\Delta \\leftarrow 0', fn: this.setDeltaNull.bind(this) },
            2: {
                depth: 1,
                text: '\\text{Loop for each } s \\in \\mathcal{S}:',
                fn: this.stateLoop.bind(this)
            },
            3: { depth: 2, text: 'v \\leftarrow V(s)', fn: this.storeValue.bind(this) },
            4: {
                depth: 2,
                text:
                    "V(s)\\leftarrow \\sum_a \\pi(a|s) \\sum_{s', r} p(s', r | s, a) [r + \\gamma V(s')]",
                fn: this.learnUpdate.bind(this)
            },
            5: {
                depth: 2,
                text: '\\Delta \\leftarrow \\text{max}(\\Delta, |v - V(s)|)',
                fn: this.updateDelta.bind(this)
            }
            // 7: {depth: 0, text: "\\text{until } \\Delta < \\theta", tex: true},
        };
        this.buildAlgoDict();
        this.numLines = Object.keys(this.algoDict).length;
        this.lineHeightPx = `${this.d.algoLineHeight}px`;
        this.lineWidthPx = `${this.d.algoLineWidth}px`;
        this.divList = new Array(this.numLines);
        // setupAlgo(xMargin, yMargin, algoWidthPx, lineHeight, lineHeightPx) {

        for (let lineIx = 0; lineIx < this.numLines; lineIx += 1) {
            this.divList[lineIx] = p.createDiv();
            this.divList[lineIx].class('algolineDiv');
            this.divList[lineIx].style('width', this.lineWidthPx);
            this.divList[lineIx].style('height', this.lineHeightPx);
            this.divList[lineIx].position(
                this.xMargin,
                this.yMargin + lineIx * this.d.algoLineHeight,
                'absolute'
            );
            kat.render(this.algoDict[lineIx].text, this.divList[lineIx].elt);
        }
        this.currentLine = 0;
        this.divList[this.currentLine].class('algolineDivActive');
    }

    buildAlgoDict() {
        Object.entries(this.algoDict).forEach(([key, val]) => {
            val.text = `\\ ${'\\ \\ \\ \\ '.repeat(val.depth)}${val.text}`;
            val.text = '\\text{' + key + ': } ' + val.text;
        });
    }

    drawAgent() {
        this.drawTitle();
    }

    drawTitle() {
        this.p.textAlign(this.p.LEFT, this.p.TOP);
        this.p.textSize(this.d.titleSize);
        this.p.text(this.title, this.xPos, this.yPos);
        this.p.textSize(this.d.textSize);
    }

    restart() {
        this.stateValues = new Array(this.d.numStates).fill(0);
        this.Delta = 1;
        this.currentV = 0;
        this.currentStateindex = -1;
        this.currentS = -1;
        this.converged = false;
        this.changeActiveLine(0);
        this.currentLine = 0;
    }

    step() {
        const newLine = this.algoDict[this.currentLine]['fn'](this.currentLine);
        this.changeActiveLine(newLine);
        this.currentLine = newLine;
    }

    changeActiveLine(newLine) {
        this.divList[this.currentLine].class('algolineDiv');
        this.divList[newLine].class('algolineDivActive');
    }

    // 0
    checkConvergence(currentLine) {
        // console.log(`this.stateValues = ${this.stateValues}`);
        let nextLine = currentLine + 1; // setDeltaNull()
        if (this.Delta < this.theta) {
            nextLine = currentLine;
            this.converged = true;
        }
        return nextLine;
    }

    // 1
    setDeltaNull(currentLine) {
        this.Delta = 0;
        return currentLine + 1; // stateLoop()
    }

    // 2
    stateLoop(currentLine) {
        this.currentStateindex += 1;
        this.currentS = this.gw.nonterminalStates[this.currentStateindex];
        return currentLine + 1; // storeValue()
    }

    // 3
    storeValue(currentLine) {
        this.currentV = this.stateValues[this.currentS];
        // console.log("currentV");
        // console.log(this.currentV);
        return currentLine + 1; // learnUpdate()
    }

    // 4
    learnUpdate(currentLine) {
        const nextState = this.gw.getNextState(this.currentS);
        const reward = this.gw.rewards[nextState];
        const nextValue = this.stateValues[nextState];
        this.stateValues[this.currentS] = reward + this.gamma * nextValue;
        return currentLine + 1; // updateDelta()
    }

    // 5
    updateDelta(currentLine) {
        this.Delta = Math.max(
            this.Delta,
            Math.abs(this.currentV - this.stateValues[this.currentS])
        );
        let newLine = 2; // stateLoop()
        if (this.currentStateindex === this.gw.numNonterminalStates - 1) {
            this.currentStateindex = -1;
            newLine = 0; // checkConvergence()
        }
        return newLine;
    }

    // chooseAction(currentState) {
    //     const currentQvalues = this.qTable[currentState];
    //     const numActions = currentQvalues.length;
    //     let argmax = 0;
    //     if (Math.random() < this.epsilon) {
    //         argmax = Math.floor(Math.random() * numActions);
    //     }
    //     let maxValue = Number.NEGATIVE_INFINITY;
    //     for (let acIx = 0; acIx < numActions; acIx += 1) {
    //         const tmpVal = currentQvalues[acIx];
    //         if (tmpVal > maxValue) {
    //             argmax = acIx;
    //             maxValue = tmpVal;
    //         }
    //     }
    //     return argmax;
    // }

    learn(state, action, reward, nextState, isTerminal) {
        const maxQvalue = isTerminal ? 0 : Math.max(...this.qTable[nextState]);
        this.qTable[state][action] =
            (1 - this.alpha) * this.qTable[state][action] + this.alpha * (reward + maxQvalue);
    }
}

// function dotProduct(a, b) {
//     let out = 0;
//     for (let ix = 0; ix < a.length; ix += 1) {
//         out += a[ix] * b[ix];
//     }
//     return out;
// }
