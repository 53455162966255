/* eslint-disable prettier/prettier */
/* eslint-disable import/no-named-as-default */
/* eslint no-param-reassign: "off", no-use-before-define: "off" */
import Gridworld from './gridworldExtended';
import IPE from './algoIPE';
import 'katex/dist/katex.min.css';

export default function sketch(p) {
    const kat = require('katex');
    let cnv = p.createCanvas(2, 2);
    // renderMathInElement(cnv);
    let cnvParent = cnv.parent();
    const sketchWidth = cnvParent.offsetWidth;
    const sketchHeight = sketchWidth; // / 2
    const margin = sketchWidth / 30;
    const halfMargin = margin / 2;
    const hSketchWidth = sketchWidth / 2;
    const hSketchHeight = sketchHeight / 2;
    const hSketchWidthMinusMargin = hSketchWidth - margin;

    // Design
    const d = {
        margin: margin,
        halfMargin: margin / 2,
        hSketchWidth: sketchWidth / 2,
        hSketchHeight: sketchHeight / 2,
        hSketchWidthMinusMargin: hSketchWidth - margin,
        algoLineHeight: 22,
        algoLineWidth: (sketchWidth * 3) / 5,

        // Text
        textSize: 12,
        titleSize: 14,
        emojiSize: 34,
        valueSize: 14,

        // Colors
        bg: p.color(240),
        ac: p.color(224),
        minColor: p.color(0, 102, 153),
        maxColor: p.color(204, 102, 0),

        // Gridworld
        numActions: 4,
        numColumns: 5,
        numRows: 5
    };
    d.numStates = d.numColumns * d.numRows;

    // Gridworld parameters
    let agent;
    let valueGridworld;
    const vGwWidth = hSketchWidthMinusMargin - margin - halfMargin;
    let policyGridworld;
    const pGwWidth = hSketchWidthMinusMargin - margin - halfMargin;

    // const numColumns = 5;
    // const numRows = 5;
    // const numStates = numColumns * numRows;
    // const numActions = 4;

    let buttonPlay;
    let buttonPause;
    let buttonRestart;
    let buttonNext;
    let buttonOptimalPolicy;
    let buttonRandomPolicy;
    let frameRateLabel;
    let frameRateSlider;
    let currentDeltaLabel;
    let currentStateLabel;
    let currentValueLabel;

    let play = false;
    let playOnce = false;

    // p.preload = () => {
    //     bombImg = p.loadImage('/../icons/bomb.png');
    //     goldImg = p.loadImage('/../icons/gold.png');
    // };

    p.setup = () => {
        // Setup canvas.
        cnv = p.createCanvas(sketchWidth, sketchHeight); // const { x: cnvX, y: cnvY} = cnv.position()
        p.clear();
        cnvParent = cnv.parent();
        cnvParent.id = 'cnvParent';
        const p5parent = p.select('#cnvParent');
        p5parent.style('position', 'relative');
        cnv.id('algocanvas');

        p.smooth();
        p.frameRate(5);

        // Setup envs and agent.
        policyGridworld = new Gridworld(
            p,
            d,
            'Policy to be evaluated:',
            0,
            0,
            pGwWidth,
            undefined,
            'policy'
        );

        valueGridworld = new Gridworld(
            p,
            d,
            'Learned value function',
            hSketchWidth,
            0, // PlusMargin, hSketchHeight
            vGwWidth,
            policyGridworld.policy,
            'value'
        );
        // rewardGridworld = new Gridworld(p, numRows, numColumns, rGwWidth, hSketchWidthPlusMargin + halfMargin,  margin, undefined);

        agent = new IPE(
            p,
            d,
            'The Algorithm: Iterative policy evaluation.',
            0,
            hSketchHeight,
            valueGridworld
        );

        setupControls(d.algoLineWidth + margin, hSketchHeight + margin);
        // setupPolicyButtons(halfMargin, hSketchHeight - margin - halfMargin/2);
        setupPolicyButtons((d.hSketchWidth * 2.2) / 5, -5);
    };

    p.draw = () => {
        const fr = frameRateSlider.value();
        p.frameRate(fr);
        p.background(255); // 209, 208, 166
        // drawBackgroundAndGrid();
        if (play || playOnce) {
            playOnce = false;
            agent.step();
        }
        valueGridworld.drawValues(agent.stateValues, agent.currentS);
        policyGridworld.drawPolicy();
        agent.drawAgent();
        kat.render('\\Delta = ' + Math.round(agent.Delta * 1000) / 1000, currentDeltaLabel.elt);
        kat.render(agent.currentS >= 0 ? 's = ' + agent.currentS : '', currentStateLabel.elt);
        kat.render('v = ' + Math.round(agent.currentV * 1000) / 1000, currentValueLabel.elt);
    };

    p.keyPressed = () => {
        if (p.keyCode === 32) {
            play = !play;
        }
        return false;
    };

    function setupControls(xPos, yPos) {
        const buttonBaseX = xPos;
        const buttonBaseY = yPos;
        const buttonWidth = 30;
        const buttonHeight = 20;
        // const speedSliderY =
        buttonNext = p
            .createImg('/../icons/baseline_skip_next_black_24dp.png')
            .position(buttonBaseX, buttonBaseY, 'absolute')
            .class('customButton')
            .mousePressed(() => {
                playOnce = true;
            });
        buttonPlay = p
            .createImg('/../icons/baseline_play_arrow_black_24dp.png')
            .position(buttonBaseX + buttonWidth, buttonBaseY, 'absolute')
            .class('customButton')
            .mousePressed(() => {
                play = true;
            });
        buttonPause = p
            .createImg('/../icons/baseline_pause_black_24dp.png')
            .position(buttonBaseX + 2 * buttonWidth, buttonBaseY, 'absolute')
            .class('customButton')
            .mousePressed(() => {
                play = false;
            });
        buttonRestart = p
            .createImg('/../icons/baseline_replay_black_24dp.png')
            .position(buttonBaseX + 3 * buttonWidth, buttonBaseY, 'absolute')
            .class('customButton')
            .mousePressed(() => {
                agent.restart();
            });

        frameRateLabel = p
            .createDiv('Speed:  ')
            .position(buttonBaseX, buttonBaseY + buttonHeight, 'absolute')
            .style('display', 'flex')
            .style('flex-direction', 'row')
            .style('align-items', 'center')
            .style('font-size', '12px');
        // .style("vertical-align", "mi");
        frameRateSlider = p
            .createSlider(1, 40, 5)
            .class('mySlider')
            // .position(buttonBaseX + 80, buttonBaseY + buttonHeight, "absolute")
            .style('width', '80px')
            .parent(frameRateLabel);

        currentStateLabel = p
            .createDiv()
            .class('labelDiv')
            .position(buttonBaseX, buttonBaseY + 2 * buttonHeight, 'absolute');

        currentDeltaLabel = p
            .createDiv()
            .class('labelDiv')
            .position(buttonBaseX, buttonBaseY + 3 * buttonHeight, 'absolute');

        currentValueLabel = p
            .createDiv()
            .class('labelDiv')
            .position(buttonBaseX, buttonBaseY + 4 * buttonHeight, 'absolute');

        // kat.render(this.algoDict[lineIx].text, this.divList[lineIx].elt);
    }

    function setupPolicyButtons(xPos, yPos) {
        buttonOptimalPolicy = p
            .createButton('Optimal')
            .class('policyButton')
            .position(xPos, yPos, 'absolute') // margin + pGwWidth
            .mousePressed(newOptimalPolicy);
        buttonRandomPolicy = p
            .createButton('Random')
            .class('policyButton')
            .position(xPos + 75, yPos, 'absolute')
            .mousePressed(getRandomDeterministicPolicy);
    }

    function newOptimalPolicy() {
        const policy = policyGridworld.getOptimalPolicy();
        valueGridworld.updatePolicy(policy);
        policyGridworld.updatePolicy(policy);
    }

    function getRandomDeterministicPolicy() {
        const policy = policyGridworld.getRandomDeterministicPolicy();
        valueGridworld.updatePolicy(policy);
        policyGridworld.updatePolicy(policy);
    }

    function drawBackgroundAndGrid() {
        p.push();
        p.fill(250);
        p.strokeWeight(1);
        p.rect(0, 0, sketchWidth, sketchHeight);
        p.line(sketchWidth / 2, 0, sketchWidth / 2, sketchHeight);
        p.line(0, sketchHeight / 2, sketchWidth, sketchHeight / 2);
        p.pop();
    }
}
