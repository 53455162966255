import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Sketch from '../../components/sketch';
import sketchGrid from '../../scripts/archive/sGridworld';
import sketchIPE from '../../scripts/dp/sIPE';
import Layout from '../../components/layout';
import DateConverter from '../../components/dateConverter';
export const _frontmatter = {
  "title": "Dynamic Programming",
  "date": "2020-01-05",
  "slug": "dp",
  "author": "JAN MALTE LICHTENBERG"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <Layout mdxType="Layout">
      <div>
        <DateConverter frontmatter={props.pageContext.frontmatter} mdxType="DateConverter" />
        <p className="blogtitle">
          {props.pageContext.frontmatter.title}
        </p>
      </div>
      <Sketch sketch={sketchIPE} mdxType="Sketch" />
      <p><br />
        <br /></p>
      <h4>{`Rules`}</h4>
      <p>{`The agent (red sphere) can navigate the gridworld environment using one of four `}
        <em {...{
          "parentName": "p"
        }}>{`actions`}</em>
        {` at each time step.`}</p>
    </Layout>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      